import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'The Fairmont',
    useCase: 'VIDEO PRODUCTION, CREATIVE DIRECTION',
    description: `I was engaged by The Fairmont in Seattle to produce and develop these two video campaigns . They highlight The Georgians private dining room where we held their signature High Tea as well as the development and launch of their internal Olympic Honey Vodka.

    Enjoy.`,
  },
  caseId: 'fairmont',
  contents: [
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/mV5JMZ8A-OmmZTDe4.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/mV5JMZ8A-1920.jpg',
      },
    },
    {
      jwplayer: {
        videoSrc: 'https://content.jwplatform.com/videos/EpRKFZ3R-OmmZTDe4.mp4',
        imageSrc: 'https://content.jwplatform.com/thumbs/EpRKFZ3R-1920.jpg',
      },
    },
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
